import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/coremedia-ci/documentation-site/documentation-site/src/components/ArticleLayout/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <ul>
      <li parentName="ul">{`Skills settings: Added functionality that shows the list of wrap up codes and bundles ordered by the same order configured on skill classification settings.`}</li>
      <li parentName="ul">{`Dynamic Fields: Added functionality that makes it available to filter by any dynamic field with Auto filters - Top6.`}</li>
      <li parentName="ul">{`Rest API: Created GET list of Channels and a new endpoint to list all A/B Testing Campaigns and variants.`}</li>
      <li parentName="ul">{`Bug Fix: Fixed the error saving Dahsboards Position on Shared Dashsboards.`}</li>
      <li parentName="ul">{`Bug fix: Fixed the error that can not read properties on microphonePermissionNotification.`}</li>
      <li parentName="ul">{`Bug fix: Fixed the error getting column in metricRuleManager`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      